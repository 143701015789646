export const localization = {
    en: {
        menu: {
            aboutProduct: 'About product',
            aboutCompany: 'About company',
            vacancy: 'Vacancy',
            demo: 'Demo versions',
            documentation: 'Documentation',
            getIsFront: 'Get isFront',
        }
    },
    ru: {
        menu: {
            aboutProduct: 'О продукте',
            aboutCompany: 'О компании',
            vacancy: 'Вакансии',
            demo: 'Демо-версии',
            documentation: 'Документы',
            getIsFront: 'Получить isFront',
        },
        titleTail: ' — Информационные системы',
        page: {
            notFound: {
                title: 'Страница не найдена',
                metaTitle: 'Страница не найдена',
                text: `<p>Вы попали на несуществующую страницу.</p>
                       <p>Проверьте, правильно ли вы ввели адрес страницы.</p>`,
                back: 'Вернуться на главную'
            },
            product: {
                title: 'О продукте',
                metaTitle: 'О продукте isFront',
                design: {
                    title: 'Дизайн',
                    metaTitle: 'Дизайн isFront',
                    text: `<p>Можно создавать любую индивидуальность, благодаря разделению системы ДБО isFront на бэк и
                          фронтальный слой через API. Делаем это с функциональным или с графическим наполнением.</p>
                       <p>С помощью API можно самостоятельно разрабатывать мобильные и веб-приложения. Спроектируем
                          под любые задачи заказчика различные web- и мобильные интерфейсы, согласно требований UX/UI,
                          Google Material Design, iOS Guidelines.</p>`
                },
                tech: {
                    title: 'Технологии',
                    metaTitle: 'Технологии isFront',
                    text: `<p>isFront можно развернуть на свободно распространяемом ПО, например: ОС - CentOS, СУБД - PostgreSQL.<br />
                            isFront работает полностью на отечественном ПО, входящем в государственный реестр: ОС, СУБД, JDK.</p>
                        <div class="block-tech">
                            <h3>Используемые технологии:</h3>
                            <div class="tech-column">
                                <ul>
                                    <li>CentOS</li>
                                    <li>RHEL</li>
                                    <li>Rosa</li>
                                    <li>PostgreSQL</li>
                                    <li>Квант-Гибрид</li>
                                    <li>LiberCat</li>
                                    <li>Jetty</li>
                                    <li>ActiveMQ</li>
                                    <li>Artemis</li>
                                </ul>
                                <ul>
                                    <li>Java</li>
                                    <li>Kotlin</li>
                                    <li>Swift</li>
                                    <li>Groovy</li>
                                </ul>
                                <ul>
                                    <li>Angular</li>
                                    <li>Micronaut</li>
                                    <li>Grails</li>
                                    <li>Flutter</li>
                                </ul>
                            </div>
                        </div>`
                },
                safety: {
                    title: 'Безопасность',
                    metaTitle: 'Безопасность isFront',
                    text: `<p>В соответствии с требованиями положений ЦБ РФ № 382-П и 683-П, isFront прошел анализ по ОУД-4,
                            и наши клиенты получили соответствующее заключение точно в срок. Мы поддерживаем актуальность
                            этого заключения – наши клиенты уже получили обновленное заключение на 2021 год. Сегодня isFront
                            проходит сертификационные испытания согласно требований 719-П.</p>
                            <p>Мы давние партнеры компаний: Фродекс, Сигнал-Ком, Актив, Аладдин, СэйфТек. Это обеспечивает
                            ДБО isFront надежной защитой от попыток несанкционированного доступа.</p>`
                },
                functional: {
                    title: 'Функциональность',
                    metaTitle: 'Функциональность isFront',
                    text: `<p>ДБО isFront реализует весь необходимый функционал - от классических платежей до сложных
                              внешнеторговых валютных контрактов. Уникальность системы в ее мощной реализации функционала
                              физических и юридических лиц в одном ДБО.</p>
                              <p>Клиентам это дает удобное переключение между ролями в одном интерфейсе. Банку – сокращение
                              расходов на поддержание разных систем ДБО. </p>`
                },
                isfront: {
                    title: 'Почему isFront?',
                    metaTitle: 'Почему isFront',
                    text: `<p><strong>Наш цифровой бутик работает 14 лет и реализовал 60+ кастомных решений ДБО isFront.</strong>
                           Нас смело рекомендуют клиенты, добро пожаловать!</p>
                           <p><strong>Рынок ДБО сегодня - это массмаркет шаблонных решений.</strong> Но 2020 год поставил
                           перед банками вопрос ребром: или меняться под клиента или уходить с рынка.<br />
                           Для банков, использующих систему ДБО isFront, вопрос так не стоит. <strong>Потому что мы даём
                           банкам главное - развитие, свободу и независимость!</strong></p>`,
                    productName: `
                            <span class="dark-blue">ДБО</span>
                            <span class="blue">is<span>F</span>ront</span>
                          `,
                    planetOneTitle: '14',
                    planetOneText: 'Лет на рынке',
                    planetTwoTitle: '60+',
                    planetTwoText: 'Внедрений',
                    planetThreeTitle: 'Все',
                    planetThreeText: 'Решения кастомные',
                    planetFourTitle: '719-П',
                    planetFourText: 'Выполнена',
                    planetFiveTitle: 'ОУД-4',
                    planetFiveText: 'Выполняется'
                }
            },
            company: {
                title: 'О компании',
                metaTitle: 'О компании',
                quality: {
                    title: 'Качество',
                    metaTitle: 'Качество isFront',
                    text: `<p>Качество поддержки системы - уникально. Оно превосходит ожидания клиентов:</p>
                           <ul>
                            <li><strong>Мы даём высокую скорость ответов и решения задач.</strong></li>
                            <li><strong>Используем систему автотестов и постоянный мониторинг.</strong></li>
                            <li><strong>Обеспечиваем гарантированно быстрый ответ, согласно условиям SLA.</strong></li>
                           </ul>`
                },
                strategy: {
                    title: 'Стратегия',
                    metaTitle: 'Стратегия isFront',
                    text: `<p><strong>Наше преимущество - скорость реализации.</strong><br />
                              Мы даём свободу в развитии.<br />
                              Отличаемся проактивностью.<br />
                              Переводим с языка бизнеса задачи и воплощаем реальные потребности.</p>
                            <p>Свободу и независимость дадут:</p>
                            <ul>
                               <li>Конструктор настраиваемых форм</li>
                               <li>Открытое API</li>
                               <li>Исходный код системы</li>
                            </ul>`,
                    planetText: 'лет назад',
                    planetYellow: 'ФИЗЮРИК',
                    planetViolet: 'QR-код вместо эквайринга',
                    planetBrown: 'API',
                    planetGreen: 'Прямой плагин в 1С',
                },
                implementation: {
                    title: 'Внедрения',
                    metaTitle: 'Внедрения isFront',
                    text: 'Нет текста'
                }
            },
            docs: {
                title: 'Документы',
                metaTitle: 'Документы isFront',
            },
            order: {
                title: 'Получить is<span>F</span>ront',
                metaTitle: 'Получить isFront',
                h2: 'Ваше новое ДБО',
                h2OrderSubmit: 'Заявка отправлена!',
                description: 'Получите дизайн-проект и предварительный расчёт ДБО для Вашего банка',
                descriptionOrderSubmit: `Спасибо за оставленную заявку!<br />В ближайшее время мы вышлем Вам на почту
                дизайн-проект и предварительный расчёт ДБО для Вашего банка`,
                buttonToMain: 'На главную',
                accessLink: 'Даю согласие на обработку своих персональных данных',
                accessDocumentName: 'Политика по обработке персональных данных ООО “Информационные системы”',
                errorSubmitForm: 'При отправке формы возникла ошибка',
                form: {
                    name: 'Ваше имя',
                    email: 'Ваш e-mail*',
                    note: '* поля, обязательные для заполнения',
                    button: 'Получить isFront'
                }
            },
            demo: {
                title: 'Демо-версии',
                metaTitle: 'Демо-версии isFront',
                button: 'Перейти к демо',
                slides: {
                    isfront: {
                        title: 'Функциональная демо-версия как физического, так и юридического лица по концепции "физюрик". Базовая функциональность для ваших клиентов.'
                    }
                }
            },
            vacancies: {
                title: 'Вакансии',
                metaTitle: 'Вакансии',
                more: 'Подробнее',
                respond: 'Откликнуться',
                back: 'Вернуться',
                formTitle: 'Заявка на вакансию',
                formFieldName: 'Имя',
                formFieldEmail: 'E-mail',
                formFieldPhone: 'Номер телефона',
                formButton: 'Отправить заявку',
                formFieldAccept: 'Даю согласие на обработку своих персональных данных',
                formAcceptLinkName: 'Политика по обработке персональных данных ООО “Информационные системы”',
                errorSubmitForm: 'При отправке формы возникли ошибки',
                successSubmitForm: 'Заявка успешно отправлена',
                list: {
                    designer: {
                        name: 'Дизайнер',
                        text: 'Тут должно быть описание вакансии...',
                        icon: '/assets/vacancies/designer.png',
                        image: '/assets/vacancies/designer-big.png'
                    },
                    'tech-writer': {
                        name: 'Технический писатель (специалист по технической документации)',
                        text: `<p><span>Образование:</span><br />
                                Высшее ТЕХНИЧЕСКОЕ образование (web-программиста, математик, инженер),
                                приветствуется - дополнительное образование по банковскому профилю: банковское дело,
                                финансы и кредит, экономика
                                </p>

                                <p><span>Опыт:</span><br />
                                Опыт работы: от 1 года;<br />
                                Мы НЕ РАССМАТРИВАЕМ удаленных сотрудников</p>

                                <p><span>Обязанности:</span></p>
                                <ul>
                                    <li>Создание структурированной и понятной документации для внутреннего и внешнего
                                    пользования;</li>
                                    <li>Разработка описания алгоритмов и пользовательских интерфейсов;</li>
                                    <li>Взаимодействие с разработчиками, аналитиками, тестировщиками и экспертами предметных
                                    областей для сбора информации;</li>
                                    <li>Изучение принципов и правил работы информационных систем в рамках проекта;</li>
                                    <li>Написание и доработка документации на реализованное и разрабатываемое ПО согласно
                                    ГОСТов.</li>
                                </ul>

                                <p><span>Дополнительные требования:</span></p>
                                <ul>
                                    <li>Опыт работы в области IT в качестве аналитика/ технолога/ постановщика задач/ web-разработчика от 1 года;</li>
                                    <li>Приветствуется знание банковских технологий;</li>
                                    <li>Опыт написания и корректировки технической документации (руководства, инструкции,
                                    регламенты, API и т.п.) в соответствии с различными стандартами;</li>
                                    <li>Опыт написания документации в ИТ проектах, наличие технического бэкграунда.</li>
                                    <li>Знание технической и ИТ-терминологии;</li>
                                    <li>Грамотная устная и письменная речь, способность связно излагать материал и четко
                                    формулировать мысли, иллюстрировать и структурировать информацию в удобном для
                                    восприятия виде, технически грамотно;</li>
                                    <li>Работа с большими массивами информации;</li>
                                    <li>Технический английский (свободное чтение рекомендуется, чтение со словарем -
                                    обязательно);</li>
                                    <li>Способность воспринимать большие объемы информации, готовность к обучению, умение
                                    работать в команде.</li>
                                </ul>

                                <p><span>Условия работы:</span></p>
                                <ul>
                                    <li>Испытательный срок;</li>
                                    <li>Рассматриваются кандидаты только на полный день;</li>
                                    <li>Конкурентная заработная плата (белая), официальное трудоустройство;</li>
                                    <li>Годовые и ежемесячные премии по результатам работы;</li>
                                    <li>Обучение и тренинги (внутри компании, а также посещение бизнес-семинаров,
                                    тренингов);</li>
                                    <li>Авто и велопарковка;</li>
                                    <li>Ежедневные занятия спортом;</li>
                                    <li>Брендированные сувениры компании в подарок.</li>
                                </ul>
                                <p>Мы ищем тех, кто реально хочет работать. Тех кому важен рост знаний и компетенций.
                                    Тех, для кого работа - это важная часть жизни для достижения успеха и самодостаточности.
                                    Любителей создавать видимость работы, просьба не беспокоиться.
                                    Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри
                                    компании.</p>
                        `,
                        icon: '/assets/vacancies/tech-writer.png',
                        image: '/assets/vacancies/tech-writer-big.png'
                    },
                    avtotest: {
                        name: 'Разработчик автотестов',
                        text: `<p><span>Образование:</span><br />
                                высшее</p>

                                <p><span>Опыт:</span><br />
                                опыт написания автотестов, проработки тест кейсов, подготовки тестовых данных
                                </p>

                                <p><span>Обязанности:</span><p>
                                <ul>
                                    <li>Написание программного обеспечения для тестирования основного продукта;</li>
                                    <li>Анализ результатов теста;</li>
                                    <li>Создание новых кейсов;</li>
                                    <li>Поддержка и актуализация существующих кейсов;</li>
                                    <li>Автоматизация процесса тестирования;</li>
                                    <li>Взаимодействие с разработчиками.</li>
                                </ul>

                                <p><span>Дополнительные требования:</span></p>
                                <ul>
                                    <li>Основы работы в Linux, PostgreSQL, Git;</li>
                                    <li>Знание основ ООП;</li>
                                    <li>Знание JAVA, HTML, CSS, Xpath;</li>
                                    <li>Английский язык на уровне чтения технической документации;</li>
                                    <li>Желательно знание Selenium WebDriver, JUnit, Spock, Cypress, Jenkins</li>
                                </ul>

                                <p><span>Условия работы:</span></p>
                                <ul>
                                    <li>Испытательный срок;</li>
                                    <li>Рассматриваются кандидаты только на полный день;</li>
                                    <li>Конкурентная заработная плата (белая), официальное трудоустройство;</li>
                                    <li>Годовые и ежемесячные премии по результатам работы;</li>
                                    <li>Обучение и тренинги (внутри компании, а также посещение бизнес-семинаров,
                                    тренингов);</li>
                                    <li>Авто и велопарковка;</li>
                                    <li>Ежедневные занятия спортом;</li>
                                    <li>Брендированные сувениры компании в подарок.</li>
                                </ul>
                                <p>Мы ищем тех, кто реально хочет работать. Тех кому важен рост знаний и компетенций.
                                    Тех, для кого работа - это важная часть жизни для достижения успеха и самодостаточности.
                                    Любителей создавать видимость работы, просьба не беспокоиться.
                                    Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри
                                    компании.</p>`,
                        icon: '/assets/vacancies/avtotest.png',
                        image: '/assets/vacancies/avtotest-big.png'
                    },
                    administrator: {
                        name: 'Администратор ДБО (QA-инженер)',
                        text: `<p><span>Образование:</span><br />
                                Высшее техническое образование, неоконченное высшее, средне-специальное (Программист,
                                математик, инженер, экономист, менеджер). </p>

                                <p><span>Опыт:</span></p>
                                <ul>
                                    <li>ПК - опытный пользователь;</li>
                                    <li>Английский язык на уровне чтение профессиональной литературы.</li>
                                </ul>

                                <p><span>Обязанности:</span></p>
                                <ul>
                                    <li>Сопровождение систем ДБО, администрирование системы "isFront";</li>
                                    <li>Работа с клиентами -банками: прием обращений о проблемах систем ДБО и их
                                    решение;</li>
                                    <li>Участие в написании технической документации, инструкций, порядков, регламентов;</li>
                                    <li>Тестирование нового ПО, диагностика ошибок;</li>
                                    <li>Внедрение и сопровождение ПО.</li>
                                </ul>

                                <p><span>Дополнительные требования:</span></p>
                                <ul>
                                    <li>знание принципов работы ДБО.</li>
                                    <li>опыт сопровождения систем ДБО (BS-Client v.3 - БСС, или любого другого
                                    разработчика).</li>
                                    <li>знание принципов информационной безопасности в платежных системах,
                                    ориентирование в понятиях ЭП, шифрования, секретный/открытый ключи.</li>
                                    <li>приветствуется знание банковской бухгалтерии и банковских бизнес-процессов.</li>
                                    <li>умение писать простые SQL-запросы.</li>
                                    <li>умение слышать и понимать, навыки системного аналитика.</li>
                                    <li>опыт составления ТЗ - обязательно.</li>
                                    <li>опыт работы с ДБО в качестве администратора или специалиста технической
                                    поддержки - является приоритетным в рассмотрении резюме соискателей.</li>
                                </ul>

                                <p><span>Условия работы:</span></p>
                                <ul>
                                    <li>Испытательный срок;</li>
                                    <li>Рассматриваются кандидаты только на полный день;</li>
                                    <li>Конкурентная заработная плата (белая), официальное трудоустройство;</li>
                                    <li>Годовые и ежемесячные премии по результатам работы;</li>
                                    <li>Обучение и тренинги (внутри компании, а также посещение бизнес-семинаров,
                                    тренингов);</li>
                                    <li>Авто и велопарковка;</li>
                                    <li>Ежедневные занятия спортом;</li>
                                    <li>Брендированные сувениры компании в подарок.</li>
                                </ul>
                                <p>Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций.
                                    Тех, для кого работа - это важная часть жизни для достижения успеха и самодостаточности.
                                    Любителей создавать видимость работы, просьба не беспокоиться.
                                    Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри
                                    компании</p>`,
                        icon: '/assets/vacancies/administrator.png',
                        image: '/assets/vacancies/administrator-big.png'
                    },
                    'developer-java': {
                        name: 'Программист-разработчик Java',
                        text: `<p><span>Образование:</span></p>
                                <ul>
                                    <li>высшее техническое</li>
                                    <li>неполное высшее техническое</li>
                                    <li>средне-специальное техническое</li>
                                </ul>

                                <p><span>Опыт:</span><br />
                                Опыт разработки JAVA не менее 1 года (рассматриваем так же программистов без
                                опыта работы в компаниях, но с опытом самостоятельного программирования).</p>

                                <p><span>Обязанности:</span><br />
                                разработка систем ДБО</p>

                                <p><span>Дополнительные требования:</span></p>
                                <ul>
                                    <li>НЕ РАССМАТРИВАЕМ удаленных сотрудников;</li>
                                    <li>Уверенное владение HTML, CSS, JS;</li>
                                    <li>Опыт создания Web-приложений (понимание соответствующего стека Java-технологий,
                                    знание протоколов TCP/IP, HTTP/HTTPS, знание JavaScript и HTML);</li>
                                    <li>Хорошие навыки (чтение технической документации) английского языка;</li>
                                    <li>Умение работать в команде и решать задачи самостоятельно;</li>
                                    <li>Уверенное владение HTML, CSS, JS;</li>
                                    <li>Знание и опыт разработки на groovy приветствуется.</li>
                                </ul>

                                <p><span>Условия работы:</span></p>
                                <ul>
                                    <li>Испытательный срок;</li>
                                    <li>Рассматриваются кандидаты только на полный день;</li>
                                    <li>Конкурентная заработная плата (белая), официальное трудоустройство;</li>
                                    <li>Годовые и ежемесячные премии по результатам работы;</li>
                                    <li>Обучение и тренинги (внутри компании, а также посещение бизнес-семинаров,
                                    тренингов);
                                    <li>Авто и велопарковка;</li>
                                    <li>Ежедневные занятия спортом;</li>
                                    <li>Брендированные сувениры компании в подарок</li>
                                </ul>
                                <p>Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций.
                                    Тех, для кого работа - это важная часть жизни для достижения успеха и самодостаточности.
                                    Любителей создавать видимость работы, просьба не беспокоиться.
                                    Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри
                                    компании.</p>`,
                        icon: '/assets/vacancies/java-developer.png',
                        image: '/assets/vacancies/java-developer-big.png'
                    },
                    sysadmin: {
                        name: 'Системный администратор',
                        text: `<p><span>Образование:</span><br />
                                Образование высшее техническое, либо неоконченное высшее техническое, средне-специальное.</p>

                                <p><span>Опыт:</span><br />
                                Опыт работы системным администратором не менее 3 лет</p>

                                <p><span>Обязанности:</span><br />
                                Контроль соблюдения внутреннего техпроцесса; обеспечение информационной и сетевой
                                безопасности; обслуживание локальной и глобальной сети предприятия, обеспечение их
                                бесперебойного функционирования; настройка и обслуживание сетевого оборудования, в т.ч.
                                маршрутизаторов, VPN, шлюзов, файерволов и т.д. настройка и обслуживание серверов под
                                опер. cист CentOS Linux, FreeBSD и vSphere ESXi; обеспечение корректной работы системы
                                OpenLDAP directory обеспечение планового обслуживания серверной техники; построение отказоустойчивых систем.</p>

                                <p><span>Дополнительные требования:</span></p>
                                <ul>
                                    <li>Опыт работы с ОС FreeBSD</li>
                                    <li>Знание северного железа HP: HP Blade, HP Storage (EVA)</li>
                                    <li>Понимание/навыки работы с ситемами виртуизации KVM, VirtualBox, hyper-v</li>
                                    <li>Опыт работы с СУБД Oracle</li>
                                    <li>Знание систем конфигурации: chef, ansible, salt stack</li>
                                    <li>Опыт работы/администрирования приложений: amanda backup/bacula, jira, spam assassin, zabbix</li>
                                    <li>Знание принципов работы RPM: сборка, сопровождение репозитариев.</li>
                                    <li>Коммуникабельность и клиентоориентированность</li>
                                    <li>умение работать с большими объемами информации</li>
                                    <li>умение быстро анализировать ситуацию и принимать решения</li>
                                    <li>системное мышление</li>
                                    <li>высокая ответственность и нацеленность на результат</li>
                                </ul>

                                <p><span>Условия работы:</span><br />
                                Официальное трудоустройство Конкурентная заработная плата (белая). Обучение и тренировки
                                (внутри компании, а также посещение бизнес-семинаров, тренингов). Если вы просто хотите
                                присесть на оклад или просто получать деньги за ничегонеделание‚ то не звоните нам‚ мы
                                таких увольняем!!! Нам нужны те‚ кто по-настоящему хочет работать и зарабатывать большие
                                деньги‚ настоящие бойцы!!» Испытательный срок.</p>`,
                        icon: '/assets/vacancies/sysadmin.png',
                        image: '/assets/vacancies/sysadmin-big.png'
                    },
                    'mobile-developer': {
                        name: 'Разработчик (IOS, Android) мобильных приложений',
                        text: `<p><span>Образование:</span><br />
                                высшее</p>

                                <p><span>Опыт:</span></p>
                                <ul>
                                    <li>Стаж работы от 1 года;</li>
                                    <li>Опыт работы разработчиком мобильных приложений от 1 года (рассматриваем также
                                    программистов без опыта работы в компаниях, но с опытом самостоятельного
                                    программирования);</li>
                                    <li>Обязательно наличие имеющихся работ (портфолио).</li>
                                </ul>

                                <p><span>Обязанности:</span></p>
                                <ul>
                                    <li>Создание приложений для мобильных платформ под управлением iOS; или Android;</li>
                                    <li>Отличное знание принципов ООП и умение применять их на практике;</li>
                                    <li>Анализ новых технологий на предмет их внедрения.</li>
                                </ul>

                                <p><span>Дополнительные требования:</span></p>
                                <ul>
                                    <li>Полный рабочий день, полная занятость;</li>
                                    <li>Android SDK/Java;</li>
                                    <li>Apple XCode/Swift;</li>
                                    <li>Технический английский (свободное чтение рекомендуется, чтение со словарем -
                                    обязательно);</li>
                                    <li>Способность воспринимать большие объемы информации, готовность к обучению,
                                    умение работать в команде;</li>
                                    <li>Знание UI паттернов, а также знание Json/XML, REST;</li>
                                    <li>Знание систем контроля версий(SVN,Git);</li>
                                    <li>Опыт работы с Jira.</li>
                                </ul>

                                <p><span>Условия работы:</span></p>
                                <ul>
                                    <li>Испытательный срок;</li>
                                    <li>Рассматриваются кандидаты только на полный день;</li>
                                    <li>Конкурентная заработная плата (белая), официальное трудоустройство;</li>
                                    <li>Годовые и ежемесячные премии по результатам работы;</li>
                                    <li>Обучение и тренинги (внутри компании, а также посещение бизнес-семинаров,
                                    тренингов);</li>
                                    <li>Авто и велопарковка;</li>
                                    <li>Ежедневные занятия спортом;</li>
                                    <li>Брендированные сувениры компании в подарок.</li>
                                </ul>
                                <p>Мы ищем тех, кто реально хочет работать. Тех кому важен рост знаний и компетенций.
                                    Тех, для кого работа - это важная часть жизни для достижения успеха и самодостаточности.
                                    Любителей создавать видимость работы, просьба не беспокоиться.
                                    Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри
                                    компании.</p>`,
                        icon: '/assets/vacancies/mobile-developer.png',
                        image: '/assets/vacancies/mobile-developer-big.png'
                    },
                    'flutter-developer': {
                        name: 'Разработчик Flutter',
                        text: `<p xmlns="http://www.w3.org/1999/html">
                                Ищем разработчика в компанию, которая ведет разработку финансовых решений, обеспечивающих комфортный пользовательский опыт при высокой транзакционной нагрузке.
                                </p>

                                <p>
                                Мы ищем профессионалов, которые умеют работать в условиях постоянных изменений. <br />
                                РАБОТА ТОЛЬКО В ОФИСЕ, ТОЛЬКО В ЕКАТЕРИНБУРГЕ <br />
                                ВНИМАНИЕ: Удаленных сотрудников не рассматриваем! <br />
                                </p>

                                <p><span>Опыт:</span><br />
                                Опыт работы от 1 года (рассматриваем так же программистов без опыта работы в компаниях, но с опытом самостоятельного программирования). Обязательно наличие имеющихся работ (портфолио).
                                </p>

                                <p><span>Обязанности:</span></p>
                                <ul>
                                    <li>Разработка flutter приложений;</li>
                                    <li>Анализ новых технологий на предмет их внедрения;</li>
                                </ul>

                                <p><span>Требования:</span></p>
                                <ul>
                                    <li>Знание Flutter;</li>
                                    <li>Технический английский (свободное чтение рекомендуется, чтение со словарем -
                                    обязательно);</li>
                                    <li>Способность воспринимать большие объемы информации, готовность к обучению,
                                    умение работать в команде;</li>
                                    <li>Знание Json/XML, REST, rxdart, Dio, Firebase;</li>
                                    <li>Знание систем контроля версий(Git);</li>
                                    <li>Опыт работы с Jira, youtrack;</li>
                                </ul>

                                <p><span>Условия работы:</span></p>
                                <ul>
                                    <li>Испытательный срок;</li>
                                    <li>Рассматриваются кандидаты только на полный день;</li>
                                    <li>Конкурентная заработная плата (белая), официальное трудоустройство;</li>
                                    <li>Годовые и ежемесячные премии по результатам работы;</li>
                                    <li>Обучение и тренинги (внутри компании, а также посещение бизнес-семинаров,
                                    тренингов);</li>
                                    <li>Авто и велопарковка;</li>
                                    <li>Ежедневные занятия спортом;</li>
                                    <li>Брендированные сувениры компании в подарок.</li>
                                </ul>
                                <p>Мы ищем тех, кто реально хочет работать. Тех кому важен рост знаний и компетенций.
                                    Тех, для кого работа - это важная часть жизни для достижения успеха и самодостаточности.
                                    Любителей создавать видимость работы, просьба не беспокоиться.
                                    Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри
                                    компании.</p>`,
                        icon: '/assets/vacancies/flutter-developer.png',
                        image: '/assets/vacancies/flutter-developer.png'
                    }
                }
            }
        }
    }
};
